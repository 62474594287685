<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4 mb--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('workbench_config')}}</h3>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_account')" v-on:keyup="filter"/>                            
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                        <el-table-column :label="tt('company')" :prop="tt('company')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{row.company_code}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('name')" :prop="tt('name')" min-width="150px" sortable>
                            <template v-slot="{row}">
                                {{row.name}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('role')" :prop="tt('role')" min-width="150px" sortable>
                            <template v-slot="{row}">
                                <span v-for="v in row.roles">
                                    <label class="badge badge-success">{{v.name}}</label>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('target')" :prop="tt('company')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                <input type="text" class="form-control form-control-sm" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="table.data[table.data.map(function(d) { return d['id']; }).indexOf(row.id)].target" @change="handleChange(row.namecode,table.data[table.data.map(function(d) { return d['id']; }).indexOf(row.id)].target)" v-if="!loadEdit">
                                <span v-else><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <page-loading v-else/>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import Api from '@/helpers/api';
import WorkbenchMaterial from '@/services/workbench/WorkbenchMaterial.service';

export default {
    data(){
        return  {
            loadEdit: false,
            onLoad:true,
            table: {
                search: '',
                data:[],
                total:0,
                page: {
                    current_page: 1,
                    per_page: 100,
                },
            }
        }
    },
    mounted() {
        this.get();
    },
    methods : {
        get(){
            let context = this;
            Api(context, WorkbenchMaterial.workbench_config(this.table.page.current_page, {per_page: 'none', search: this.table.search}))
            .onSuccess((response) => {
                context.table.data = response.data.data.data.data;
                context.table.total = response.data.data.data.total;
                context.table.page = response.data.data.data;
                context.onLoad     = false;
            }).onError((error) =>{
                context.table.data = [];
                context.onLoad     = false;
            }).call()
        },
        handleChange(namecode, target){
            this.loadEdit = true;
            let context = this;
            let formData = new FormData();
            formData.append('name', namecode)
            formData.append('target', target)
            Api(context, WorkbenchMaterial.workbench_config_edit(formData))
            .onSuccess((response) => {
                context.loadEdit = false;
            }).onError((error) =>{
                context.loadEdit = false;
            }).call();
        },
        changePage(page) {
            let context = this;
            context.onLoad = true;

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.get()
            }, 100);
        },
        filter(){
            let context = this;
            context.onLoad = true;
            this.table.page.current_page = 1;

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.get()
            }, 100);
        }
    }
}
</script>

<style>
/* .pointer {cursor: pointer;} */
</style>