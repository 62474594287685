var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-6 col-7"},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"})])])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header mt--4 mb--4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.tt('workbench_config')))])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.table.search),expression:"table.search"}],staticClass:"form-control form-control-sm text-center border-radius-20",attrs:{"type":"text","placeholder":_vm.tt('search_account')},domProps:{"value":(_vm.table.search)},on:{"keyup":_vm.filter,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.table, "search", $event.target.value)}}})])])])]),_c('div',{staticClass:"table-responsive"},[(!_vm.onLoad)?_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"height":"350px","header-row-class-name":"thead-light","data":_vm.table.data}},[_c('el-table-column',{attrs:{"label":_vm.tt('company'),"prop":_vm.tt('company'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.company_code)+" ")]}}],null,false,725956298)}),_c('el-table-column',{attrs:{"label":_vm.tt('name'),"prop":_vm.tt('name'),"min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}],null,false,4085208056)}),_c('el-table-column',{attrs:{"label":_vm.tt('role'),"prop":_vm.tt('role'),"min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.roles),function(v){return _c('span',[_c('label',{staticClass:"badge badge-success"},[_vm._v(_vm._s(v.name))])])})}}],null,false,2209630245)}),_c('el-table-column',{attrs:{"label":_vm.tt('target'),"prop":_vm.tt('company'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!_vm.loadEdit)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.table.data[_vm.table.data.map(function(d) { return d['id']; }).indexOf(row.id)].target),expression:"table.data[table.data.map(function(d) { return d['id']; }).indexOf(row.id)].target"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","aria-label":"Small","aria-describedby":"inputGroup-sizing-sm"},domProps:{"value":(_vm.table.data[_vm.table.data.map(function(d) { return d['id']; }).indexOf(row.id)].target)},on:{"change":function($event){_vm.handleChange(row.namecode,_vm.table.data[_vm.table.data.map(function(d) { return d['id']; }).indexOf(row.id)].target)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.table.data[_vm.table.data.map(function(d) { return d['id']; }).indexOf(row.id)], "target", $event.target.value)}}}):_c('span',[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(_vm._s(_vm.tt("please_wait")))])]}}],null,false,3152437149)})],1):_c('page-loading'),_c('div',{staticClass:"card-footer pb-0 "},[_c('span',{staticClass:"float-left"},[_c('base-pagination',{attrs:{"page-count":_vm.table.page.last_page,"size":"sm"},nativeOn:{"click":function($event){return _vm.changePage(_vm.table.page.current_page)}},model:{value:(_vm.table.page.current_page),callback:function ($$v) {_vm.$set(_vm.table.page, "current_page", $$v)},expression:"table.page.current_page"}})],1),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.tt('page_info', {size: (_vm.table.page.current_page - 1) * _vm.table.page.per_page + _vm.table.data.length, total: _vm.table.total}))+" ")])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }